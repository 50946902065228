import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { TagComponent } from '@commons/tag/tag.component';
import { AlgoliaService } from '@core/services/algolia.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { PARTNER_ID_LABEL_MAPPING } from '@models/banking-partner-mapping';
import { AlgoliaClient } from '@models/client.model';
import { Store } from '@ngrx/store';
import { selectCurrentCountry } from '@stores/router/router.selectors';
import { BankingPartnerEnum } from '@wizbii/utils/models';
import { derivedAsync } from 'ngxtension/derived-async';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

@Component({
  selector: 'app-global-searchbar',
  imports: [
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    TagComponent,
  ],
  template: `
    <div class="tw:bg-white tw:text-primary tw:rounded-[10px] tw:flex tw:items-center">
      <input
        class="tw:grow tw:px-3 tw:py-3.5 tw:rounded-[10px]"
        [matAutocomplete]="auto"
        [formControl]="searchCtrl"
        matInput
        aria-label="Rechercher un client"
        placeholder="Rechercher un client"
      />
      <mat-icon class="tw:m-3" svgIcon="search"></mat-icon>
      <mat-autocomplete
        [displayWith]="displayFn"
        (optionSelected)="selectClient($event.option.value)"
        #auto="matAutocomplete"
      >
        @for (hit of algoliaSearch(); track hit._id) {
          <mat-option class="tw:p-3!" [value]="hit">
            <div>
              {{ hit.fullName }}

              @if (hit.partnerId === BankingPartner.Swan) {
                <app-tag [innerClass]="hit.partnerId">
                  <mat-icon class="tag-swan" svgIcon="swan"></mat-icon>
                </app-tag>
              } @else {
                <app-tag
                  [customClasses]="'partner-bg--' + hit.partnerId"
                  innerClass="partner"
                  label="{{ partnerIdLabelMapping[hit.partnerId] }}"
                >
                </app-tag>
              }
            </div>
            <div class="tw:pt-2 tw:text-xs">
              @if (hit.phoneNumber) {
                <b>{{ hit.phoneNumber }}</b> | {{ hit.email }}
              } @else {
                {{ hit.email }}
              }
            </div>
          </mat-option>
        }
      </mat-autocomplete>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchbarComponent {
  readonly #algoliaService = inject(AlgoliaService);
  readonly #store = inject(Store);
  readonly currentCountry = this.#store.selectSignal(selectCurrentCountry);
  readonly #router = inject(Router);

  partnerIdLabelMapping = PARTNER_ID_LABEL_MAPPING;
  readonly BankingPartner = BankingPartnerEnum;
  searchCtrl = new FormControl();
  algoliaIndex = derivedAsync(() => this.#algoliaService.getIndex(environment.algolia.index.client));

  algoliaSearch = derivedAsync(() => {
    return this.searchCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(async (query) => {
        if (!query || typeof query !== 'string') return [];

        const algoliaRes = await this.algoliaIndex()?.search<AlgoliaClient>('', {
          query,
          page: 0,
          cacheable: false,
        });

        return algoliaRes?.hits || [];
      })
    );
  });

  selectClient(client: AlgoliaClient): void {
    this.#router.navigate(['/', this.currentCountry(), FeaturesRoutingEnum.Clients, client.userId]);
    this.searchCtrl.reset();
  }

  displayFn(client: AlgoliaClient): string {
    return client && client.fullName ? client.fullName : '';
  }
}
